import rawRequest, { ResponseError } from 'umi-request';
import { history } from 'umi';
import { notification } from 'antd';

export const errorHandler = async (error: ResponseError) => {
  const { response } = error;

  let rst: any;
  if (response && response.status) {
    const { status, url } = response;
    console.log(status, url);

    try {
      rst = await response.json();
    } catch {
      rst = null;
      console.log('non-json response');
    }

    if (status === 400) {
      if (rst.message === 'invalidate token') {
        history.replace(`/password/result/invalidate_token`);
      }
    } else {
      notification.error({
        message: rst?.message || rst?.msg || 'No response from server.',
      });
    }
  }
  throw error;
};

export const validateToken = (token: string) => {
  return rawRequest.get(`${API_URL}/v1/admin_account/login/valid`, {
    params: { token },
    errorHandler,
  });
};

export const setPassword = (password: string, token: string) => {
  return rawRequest.post(`${API_URL}/v2/admin_account/login/reset`, {
    data: { password, token },
    errorHandler,
  });
};
