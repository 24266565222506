import { Model } from 'dva';

type GlobalModel = Omit<Model, 'state'> & { state: GlobalState };

export default {
  namespace: 'global',
  state: {
    languages: [
      {
        title: 'EN',
        key: 'en-US',
      },
      {
        title: 'ID',
        key: 'id-ID',
      },
      {
        title: 'VN',
        key: 'vi-VN',
      },
      {
        title: 'TH',
        key: 'th-TH',
      },
      {
        title: 'ZH',
        key: 'zh-CN',
      },
    ],
    currentLang: 'en-US',
    token: '',
  },
  reducers: {
    setData(state: any, { payload }: any) {
      return {
        ...state,
        ...payload,
      };
    },
  },
} as GlobalModel;
