import { FC, ReactElement, useEffect } from 'react';
import { IRouteComponentProps } from 'umi';
import { IntlProvider } from 'react-intl';
import { useSelector, useDispatch } from 'dva';
import { parseUrlParams } from '@/utils/common';
import { validateToken } from '@/service/global';
import id from '@/locales/id-ID';
import en from '@/locales/en-US';
import zh from '@/locales/zh-CN';
import vi from '@/locales/vi-VN';
import th from '@/locales/th-TH';

const locales: any = {
  'id-ID': id,
  'en-US': en,
  'zh-CN': zh,
  'vi-VN': vi,
  'th-TH': th,
};

const Layout: FC<IRouteComponentProps> = ({
  children,
  location,
}: IRouteComponentProps): ReactElement => {
  const { currentLang } = useSelector<ReduxState, GlobalState>(
    (state) => state.global,
  );

  const dispatch = useDispatch();

  // validate token
  useEffect(() => {
    const { token, lang } = parseUrlParams(location.search);
    !!token &&
      validateToken(token).finally(() => {
        dispatch({
          type: 'global/setData',
          payload: {
            token,
            currentLang: lang,
          },
        });
      });
  }, []);
  return (
    <IntlProvider
      locale={currentLang || 'en-US'}
      messages={locales[currentLang || 'en-US'] || en}
    >
      {children}
    </IntlProvider>
  );
};

export default Layout;
