import { FC, ReactElement } from 'react';
import { IRouteComponentProps } from 'umi';
import { Grid } from 'antd';
import { useIntl } from 'react-intl';
import { LoginPage, Typography } from 'iglooform';
import { AlertFilled, SuccessFilled } from 'iglooicon';
import { SwitchLang } from '@/components/switch-lang';
import styles from '../index.less';

const ResultPage: FC<IRouteComponentProps> = (
  props: IRouteComponentProps,
): ReactElement => {
  let pathState: { type?: string } = {};
  const { md } = Grid.useBreakpoint();
  const { formatMessage } = useIntl();
  const { code }: { code?: string | undefined } = props.match.params;
  if (!!props.location.state) {
    pathState = props.location.state;
  }
  return (
    <LoginPage showAppStore>
      <SwitchLang
        className={md ? styles.selectLang : styles.selectLangMobile}
      />
      <div
        className={md ? styles.loginContent : styles.loginContentMobile}
        style={{ marginTop: '50px' }}
      >
        <div style={{ textAlign: 'center' }}>
          <div style={{ marginBottom: '8px' }}>
            {code === 'success' ? (
              <SuccessFilled style={{ fontSize: '50px', color: '#2fd573' }} />
            ) : (
              <AlertFilled style={{ fontSize: '50px', color: '#f5210a' }} />
            )}
          </div>
          <Typography level="h2">
            {code === 'success'
              ? formatMessage({
                  id: `Password ${
                    pathState.type === 'create' ? 'Created' : 'Reset'
                  } Successful`,
                })
              : formatMessage({ id: 'Expired Token' })}
          </Typography>
        </div>
        <div style={{ marginTop: '8px', textAlign: 'center' }}>
          <Typography level="h3b">
            {code === 'success'
              ? formatMessage({
                  id: 'You can now log in with your new password',
                })
              : formatMessage({
                  id: 'Please request a new password reset link',
                })}
          </Typography>
        </div>
      </div>
    </LoginPage>
  );
};

export default ResultPage;
