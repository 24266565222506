export const parseUrlParams = (url: string = '') => {
  if (url.indexOf('?') === 0) {
    url = url.substring(1);
  }
  if (url.trim() === '') {
    return {};
  }
  return url
    .split('&')
    .reduce((init: { [key: string]: string }, item: string) => {
      const key = item.split('=')[0];
      const value = decodeURIComponent(item.split('=')[1]);
      init[key] = value;
      return init;
    }, {});
};
